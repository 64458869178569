import { always, toggle } from "kremling";
import { ReactNode, useContext } from "react";
import { useLocation } from "react-router";
import { ConsoleContext } from "../Console/ConsolePage";

export function MainContent(props: Props) {
  const location = useLocation();
  const isConsole = location.pathname.startsWith("/console");
  const consoleContext = useContext(ConsoleContext);
  const leftAlign = props.leftAlign || isConsole;

  return (
    <main
      className={always(`ml-0]`)
        .maybe(`ml-[137px] mr-[120px] lg:ml-[440px] max-w-[880px]`, isConsole)
        .toggle(
          toggle(
            "mt-[10.9rem]",
            "mt-[7.75rem]",
            consoleContext.hasFreeTrialBanner
          ),
          "mt-28 lg:mt-14",
          isConsole
        )}
    >
      <div
        className={always("w-full")
          .maybe("mx-auto", !leftAlign)
          .maybe("p-4", props.padding)}
      >
        {props.children}
      </div>
    </main>
  );
}

export const getScrollMTClass = () => "scroll-mt-28 lg:scroll-mt-14";

interface Props {
  children: ReactNode;
  leftAlign?: boolean;
  padding?: boolean;
}
