import "@reach/menu-button/styles.css";
import {
  MenuItemProps as ReachMenuItemProps,
  MenuButton as ReachMenuButton,
  MenuLink as ReachMenuLink,
  MenuItem as ReachMenuItem,
  MenuPopover as ReachMenuPopover,
  MenuPopoverProps as ReachMenuPopoverProps,
} from "@reach/menu-button";
export { Menu, MenuList, MenuItems } from "@reach/menu-button";
import { ReactElement, ReactNode, forwardRef } from "react";
import {
  Button,
  buttonClasses,
  ButtonKind,
  ButtonProps,
  ButtonSize,
} from "./Button";
import { Anchor, AnchorProps } from "./Anchor";
import { Icon, IconVariant } from "./Icon";
import { Tooltip } from "./Tooltip";
import "./MenuButton.css";
import { always } from "kremling";

export function MenuButton(props: MenuButtonProps): ReactElement {
  const {
    kind,
    buttonSize = ButtonSize.large,
    className,
    disabled = false,
    ...otherProps
  } = props;
  return (
    <ReachMenuButton
      className={always(
        buttonClasses({
          kind,
          buttonSize,
          className,
          disabled,
          hasNotches: false,
        })
      ).always("inline-flex")}
      disabled={disabled}
      {...otherProps}
    >
      {props.children}
      <span aria-hidden className="ml-1">
        ▾
      </span>
    </ReachMenuButton>
  );
}

interface MenuItemProps extends Omit<ReachMenuItemProps, "disabled"> {
  missingPermissions?: string[];
  className?: string;
}

export function MenuItem(props: MenuItemProps) {
  const {
    missingPermissions = [],
    children,
    className = "",
    ...otherProps
  } = props;

  return (
    <ReachMenuItem
      className={className}
      {...otherProps}
      disabled={missingPermissions.length > 0}
    >
      {children}
      <MissingPermissionsInfoIcon missingPermissions={missingPermissions} />
    </ReachMenuItem>
  );
}

interface MenuLinkProps extends TransparentAnchorProps {
  missingPermissions?: string[];
}

export function MenuLink(props: MenuLinkProps) {
  const missingPermissions = props.missingPermissions ?? [];

  return (
    <ReachMenuLink
      as={TransparentAnchor}
      href={props.to || props.href}
      disabled={missingPermissions.length > 0}
      className="text-darkNavy hover:bg-royalBlue font-light"
    >
      {props.children}
      <MissingPermissionsInfoIcon missingPermissions={missingPermissions} />
    </ReachMenuLink>
  );
}

interface MissingPermissionsInfoIconProps {
  missingPermissions?: string[];
}

function MissingPermissionsInfoIcon(props: MissingPermissionsInfoIconProps) {
  const missingPermissions = props.missingPermissions ?? [];
  if (missingPermissions.length === 0) {
    return null;
  } else {
    return (
      <Tooltip label={`Missing permission ${missingPermissions.join(", ")}`}>
        <Button kind={ButtonKind.icon} className="ml-2" type="button">
          <Icon variant={IconVariant.infoCircle} />
        </Button>
      </Tooltip>
    );
  }
}

export const TransparentAnchor = forwardRef<
  HTMLAnchorElement,
  TransparentAnchorProps
>(({ href, ...props }, ref) => {
  return (
    <Anchor
      ref={ref}
      {...props}
      kind={ButtonKind.transparent}
      to={props.to || href}
    >
      {props.children}
    </Anchor>
  );
});

type TransparentAnchorProps = Omit<AnchorProps, "kind">;

interface MenuButtonProps extends ButtonProps {
  children: ReactNode;
}

export function MenuPopover(props: MenuPopoverProps) {
  return (
    <ReachMenuPopover
      className="border-b border-primary-600 font-light"
      {...props}
    />
  );
}

export type MenuPopoverProps = ReachMenuPopoverProps;
