import { FunctionComponent } from "react";
import {
  ChevronDownIcon as Outline24_ChevronDownIcon,
  CogIcon as Outline24_CogIcon,
  GlobeAltIcon as Outline24_GlobeAltIcon,
  PuzzlePieceIcon as Outline24_PuzzlePieceIcon,
  XMarkIcon as Outline24_XMarkIcon,
  ClipboardDocumentIcon as Outline24_ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon as Outline24_ClipboardDocumentCheckIcon,
  UserCircleIcon as Outline24_UserCircleIcon,
  UserIcon as Outline24_UserIcon,
  Bars3Icon as Outline24_Bars3Icon,
  ArrowSmallRightIcon as Outline24_ArrowSmallRightIcon,
  InformationCircleIcon as Outline24_InformationCircleIcon,
  ExclamationCircleIcon as Outline24_ExclamationCircleIcon,
  ExclamationTriangleIcon as Outline24_ExclamationTriangleIcon,
  ChevronRightIcon as Outline24_ChevronRightIcon,
  MagnifyingGlassIcon as Outline24_MagnifyingGlassIcon,
  ArrowSmallLeftIcon as Outline24_ArrowSmallLeftIcon,
  ArrowSmallUpIcon as Outline24_ArrowSmallUpIcon,
  ArrowPathIcon as Outline24_ArrowPathIcon,
  EnvelopeOpenIcon as Outline24_EnvelopeOpenIcon,
  CheckIcon as Outline24_CheckIcon,
  RectangleGroupIcon as Outline24_RectangleGroupIcon,
  ChatBubbleBottomCenterTextIcon as Outline24_ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline/index.js";
import {
  Bars3Icon as Solid20_Bars3Icon,
  CheckIcon as Solid20_CheckIcon,
  ClipboardDocumentIcon as Solid20_ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon as Solid20_ClipboardDocumentCheckIcon,
  XMarkIcon as Solid20_XMarkIcon,
  ChevronDownIcon as Solid20_ChevronDownIcon,
  EnvelopeOpenIcon as Solid20_EnvelopeOpenIcon,
  CogIcon as Solid20_CogIcon,
  GlobeAltIcon as Solid20_GlobeAltIcon,
  PuzzlePieceIcon as Solid20_PuzzlePieceIcon,
  UserCircleIcon as Solid20_UserCircleIcon,
  UserIcon as Solid20_UserIcon,
  ArrowSmallRightIcon as Solid20_ArrowSmallRightIcon,
  InformationCircleIcon as Solid20_InformationCircleIcon,
  ExclamationCircleIcon as Solid20_ExclamationCircleIcon,
  ExclamationTriangleIcon as Solid20_ExclamationTriangleIcon,
  ChevronRightIcon as Solid20_ChevronRightIcon,
  MagnifyingGlassIcon as Solid20_MagnifyingGlassIcon,
  ArrowSmallLeftIcon as Solid20_ArrowSmallLeftIcon,
  ArrowSmallUpIcon as Solid20_ArrowSmallUpIcon,
  ArrowPathIcon as Solid20_ArrowPathIcon,
  RectangleGroupIcon as Solid20_RectangleGroupIcon,
  ChatBubbleBottomCenterTextIcon as Solid20_ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/20/solid/index.js";
import {
  Bars3Icon as Solid24_Bars3Icon,
  CheckIcon as Solid24_CheckIcon,
  ClipboardDocumentIcon as Solid24_ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon as Solid24_ClipboardDocumentCheckIcon,
  XMarkIcon as Solid24_XMarkIcon,
  ChevronDownIcon as Solid24_ChevronDownIcon,
  EnvelopeOpenIcon as Solid24_EnvelopeOpenIcon,
  CogIcon as Solid24_CogIcon,
  GlobeAltIcon as Solid24_GlobeAltIcon,
  PuzzlePieceIcon as Solid24_PuzzlePieceIcon,
  UserCircleIcon as Solid24_UserCircleIcon,
  UserIcon as Solid24_UserIcon,
  ArrowSmallRightIcon as Solid24_ArrowSmallRightIcon,
  InformationCircleIcon as Solid24_InformationCircleIcon,
  ExclamationCircleIcon as Solid24_ExclamationCircleIcon,
  ExclamationTriangleIcon as Solid24_ExclamationTriangleIcon,
  ChevronRightIcon as Solid24_ChevronRightIcon,
  MagnifyingGlassIcon as Solid24_MagnifyingGlassIcon,
  ArrowSmallLeftIcon as Solid24_ArrowSmallLeftIcon,
  ArrowSmallUpIcon as Solid24_ArrowSmallUpIcon,
  ArrowPathIcon as Solid24_ArrowPathIcon,
  RectangleGroupIcon as Solid24_RectangleGroupIcon,
  ChatBubbleBottomCenterTextIcon as Solid24_ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/solid/index.js";

export function Icon({
  variant,
  kind = IconKind.outline,
  size = 16,
  alt,
  className,
  fill,
}: IconProps) {
  const { IconComponent, alt: defaultAlt } = getIconComponent(
    variant,
    kind,
    size
  );

  if (!IconComponent) {
    throw Error(`Unknown IconVariant '${variant}' with kind '${kind}'`);
  }

  const px = `${size}px`;

  return (
    <>
      <span className="sr-only">{alt ?? defaultAlt}</span>
      <IconComponent
        height={px}
        width={px}
        className={className}
        fill={fill ?? "currentColor"}
      />
    </>
  );
}

function getIconComponent(
  variant: IconVariant,
  kind: IconKind,
  size: number
): {
  IconComponent: FunctionComponent<
    React.ComponentProps<"svg"> & {
      title?: string;
      titleId?: string;
    }
  > | null;
  alt: string | null;
} {
  switch (variant) {
    case IconVariant.check:
      return {
        alt: "Checkmark",
        IconComponent: iconComponent({
          mini: Solid20_CheckIcon,
          outline: Outline24_CheckIcon,
          solid: Solid24_CheckIcon,
        }),
      };
    case IconVariant.clipboard:
      return {
        alt: "Copy to clipboard",
        IconComponent: iconComponent({
          mini: Solid20_ClipboardDocumentIcon,
          outline: Outline24_ClipboardDocumentIcon,
          solid: Solid24_ClipboardDocumentIcon,
        }),
      };
    case IconVariant.clipboardSuccess:
      return {
        alt: "Copied to clipboard",
        IconComponent: iconComponent({
          mini: Solid20_ClipboardDocumentCheckIcon,
          outline: Outline24_ClipboardDocumentCheckIcon,
          solid: Solid24_ClipboardDocumentCheckIcon,
        }),
      };
    case IconVariant.close:
      return {
        alt: "close",
        IconComponent: iconComponent({
          mini: Solid20_XMarkIcon,
          outline: Outline24_XMarkIcon,
          solid: Solid24_XMarkIcon,
        }),
      };
    case IconVariant.collapsed:
      return {
        alt: "Collapsed",
        IconComponent: iconComponent({
          mini: Solid20_ChevronRightIcon,
          outline: Outline24_ChevronRightIcon,
          solid: Solid24_ChevronRightIcon,
        }),
      };
    case IconVariant.envelopeOpen:
      return {
        alt: "Empty open envelope",
        IconComponent: iconComponent({
          mini: Solid20_EnvelopeOpenIcon,
          outline: Outline24_EnvelopeOpenIcon,
          solid: Solid24_EnvelopeOpenIcon,
        }),
      };
    case IconVariant.exclamationCircle:
      return {
        alt: "Exclamation circle",
        IconComponent: iconComponent({
          mini: Solid20_ExclamationCircleIcon,
          outline: Outline24_ExclamationCircleIcon,
          solid: Solid24_ExclamationCircleIcon,
        }),
      };
    case IconVariant.exclamationTriangle:
      return {
        alt: "Exclamation triangle",
        IconComponent: iconComponent({
          mini: Solid20_ExclamationTriangleIcon,
          outline: Outline24_ExclamationTriangleIcon,
          solid: Solid24_ExclamationTriangleIcon,
        }),
      };
    case IconVariant.expanded:
      return {
        alt: "Expanded",
        IconComponent: iconComponent({
          mini: Solid20_ChevronDownIcon,
          outline: Outline24_ChevronDownIcon,
          solid: Solid24_ChevronDownIcon,
        }),
      };
    case IconVariant.globe:
      return {
        alt: "Globe",
        IconComponent: iconComponent({
          mini: Solid20_GlobeAltIcon,
          outline: Outline24_GlobeAltIcon,
          solid: Solid24_GlobeAltIcon,
        }),
      };
    case IconVariant.hamburgerMenu:
      return {
        alt: "Hamburger menu",
        IconComponent: iconComponent({
          mini: Solid20_Bars3Icon,
          outline: Outline24_Bars3Icon,
          solid: Solid24_Bars3Icon,
        }),
      };
    case IconVariant.infoCircle:
      return {
        alt: "info circle",
        IconComponent: iconComponent({
          mini: Solid20_InformationCircleIcon,
          outline: Outline24_InformationCircleIcon,
          solid: Solid24_InformationCircleIcon,
        }),
      };
    case IconVariant.leftArrow:
      return {
        alt: "Left arrow",
        IconComponent: iconComponent({
          mini: Solid20_ArrowSmallLeftIcon,
          outline: Outline24_ArrowSmallLeftIcon,
          solid: Solid24_ArrowSmallLeftIcon,
        }),
      };
    case IconVariant.magnifyingGlass:
      return {
        alt: "Search",
        IconComponent: iconComponent({
          mini: Solid20_MagnifyingGlassIcon,
          outline: Outline24_MagnifyingGlassIcon,
          solid: Solid24_MagnifyingGlassIcon,
        }),
      };
    case IconVariant.puzzle:
      return {
        alt: "Puzzle",
        IconComponent: iconComponent({
          mini: Solid20_PuzzlePieceIcon,
          outline: Outline24_PuzzlePieceIcon,
          solid: Solid24_PuzzlePieceIcon,
        }),
      };
    case IconVariant.refresh:
      return {
        alt: "Refresh",
        IconComponent: iconComponent({
          mini: Solid20_ArrowPathIcon,
          outline: Outline24_ArrowPathIcon,
          solid: Solid24_ArrowPathIcon,
        }),
      };
    case IconVariant.rightArrow:
      return {
        alt: "Arrow Right",
        IconComponent: iconComponent({
          mini: Solid20_ArrowSmallRightIcon,
          outline: Outline24_ArrowSmallRightIcon,
          solid: Solid24_ArrowSmallRightIcon,
        }),
      };
    case IconVariant.settings:
      return {
        alt: "Settings",
        IconComponent: iconComponent({
          mini: Solid20_CogIcon,
          outline: Outline24_CogIcon,
          solid: Solid24_CogIcon,
        }),
      };
    case IconVariant.upArrow:
      return {
        alt: "Up Arrow",
        IconComponent: iconComponent({
          mini: Solid20_ArrowSmallUpIcon,
          outline: Outline24_ArrowSmallUpIcon,
          solid: Solid24_ArrowSmallUpIcon,
        }),
      };
    case IconVariant.user:
      return {
        alt: "User",
        IconComponent: iconComponent({
          mini: Solid20_UserIcon,
          outline: Outline24_UserIcon,
          solid: Solid24_UserIcon,
        }),
      };
    case IconVariant.userCircle:
      return {
        alt: "User profile",
        IconComponent: iconComponent({
          mini: Solid20_UserCircleIcon,
          outline: Outline24_UserCircleIcon,
          solid: Solid24_UserCircleIcon,
        }),
      };
    case IconVariant.webApp:
      return {
        alt: "Web app",
        IconComponent: iconComponent({
          mini: Solid20_RectangleGroupIcon,
          outline: Outline24_RectangleGroupIcon,
          solid: Solid24_RectangleGroupIcon,
        }),
      };
    case IconVariant.feedback:
      return {
        alt: "Product feedback",
        IconComponent: iconComponent({
          mini: Solid20_ChatBubbleBottomCenterTextIcon,
          outline: Outline24_ChatBubbleBottomCenterTextIcon,
          solid: Solid24_ChatBubbleBottomCenterTextIcon,
        }),
      };
  }

  function iconComponent(componentMap: ComponentMap) {
    if (size <= 20 && kind === IconKind.solid) {
      return componentMap.mini;
    } else if (kind === IconKind.solid) {
      return componentMap.solid;
    } else {
      return componentMap.outline;
    }
  }
}

type ComponentMap = {
  mini: FunctionComponent;
  solid: FunctionComponent;
  outline: FunctionComponent;
};

export enum IconKind {
  outline = "outline",
  solid = "solid",
}

export interface IconProps {
  variant: IconVariant;
  alt?: string;
  kind?: IconKind;
  size?: number;
  className?: string;
  fill?: string;
}

export enum IconVariant {
  close = "close",
  expanded = "expanded",
  collapsed = "collapsed",
  settings = "settings",
  globe = "globe",
  puzzle = "puzzle",
  clipboard = "clipboard",
  clipboardSuccess = "clipboardSuccess",
  userCircle = "userCircle",
  hamburgerMenu = "hamburgerMenu",
  rightArrow = "rightArrow",
  leftArrow = "leftArrow",
  upArrow = "upArrow",
  infoCircle = "infoCircle",
  exclamationCircle = "exclamationCircle",
  exclamationTriangle = "exclamationTriangle",
  magnifyingGlass = "magnifyingGlass",
  refresh = "refresh",
  webApp = "webApp",
  envelopeOpen = "envelopeOpen",
  check = "check",
  user = "user",
  feedback = "feedback",
}
