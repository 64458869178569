import "./Utils/dayjsUtils";
import { StaticRouter } from "react-router-dom/server.js";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createContext, lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { DocsHome } from "./Docs/DocsHome";
import { inBrowser } from "./Utils/browserHelpers";
import { UserPreferencesAttributes } from "../backend/DB/Models/User/UserPreferences";
import { RouteWithCustomerOrgId } from "./Utils/RouteWithCustomerOrgId";
import { MainContent } from "./Styleguide/MainContent";
import { Loader } from "./Styleguide/Loader";
import { BaseplateAccount } from "../backend/Utils/IAMUtils";
import { PagePermissionWall } from "./Utils/PagePermissionWall";
import { BaseplatePermission } from "../backend/Utils/IsomorphicPermissions";
import { lazyMainContentRoute } from "./Utils/suspenseUtils";
import { BaseplateUUID } from "../backend/DB/Models/SequelizeTSHelpers";
import { BodyBackgroundColor } from "./Utils/BodyBackgroundColor";
import { CustomerOrg } from "../backend/DB/Models/CustomerOrg/CustomerOrg";

const Login = lazy(() =>
  import("./Auth/Login").then((m) => ({ default: m.Login }))
);
const ResetPassword = lazy(() =>
  import("./Auth/ResetPassword").then((m) => ({ default: m.ResetPassword }))
);
const ResetPasswordEmailSent = lazy(() =>
  import("./Auth/ResetPasswordEmailSent").then((m) => ({
    default: m.ResetPasswordEmailSent,
  }))
);
const FinishResetPassword = lazy(() =>
  import("./Auth/FinishResetPassword").then((m) => ({
    default: m.FinishResetPassword,
  }))
);
const FinishAccountCreation = lazy(() =>
  import("./Auth/FinishAccountCreation").then((m) => ({
    default: m.FinishAccountCreation,
  }))
);
const Pricing = lazy(() =>
  import("./Marketing/Pricing").then((m) => ({ default: m.Pricing }))
);
const Docs = lazy(() =>
  import("./Docs/Docs").then((m) => ({ default: m.Docs }))
);
const DocsPage = lazy(() =>
  import("./Docs/DocsPage").then((m) => ({ default: m.DocsPage }))
);
const ConsolePage = lazy(() =>
  import("./Console/ConsolePage").then((m) => ({ default: m.ConsolePage }))
);
const MicrofrontendsList = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendsList").then((m) => ({
    default: m.MicrofrontendsList,
  }))
);
const MicrofrontendDetail = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendDetail").then((m) => ({
    default: m.MicrofrontendDetail,
  }))
);
const MicrofrontendHome = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendDetail").then((m) => ({
    default: m.MicrofrontendHome,
  }))
);
const MicrofrontendDeployments = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendDeployments").then((m) => ({
    default: m.MicrofrontendDeployments,
  }))
);
const MicrofrontendConfiguration = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendConfiguration").then((m) => ({
    default: m.MicrofrontendConfiguration,
  }))
);
const MicrofrontendAccess = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendAccess").then((m) => ({
    default: m.MicrofrontendAccess,
  }))
);
const EnvironmentsList = lazy(() =>
  import("./Console/Environments/EnvironmentsList").then((m) => ({
    default: m.EnvironmentsList,
  }))
);
const EnvironmentDetail = lazy(() =>
  import("./Console/Environments/EnvironmentDetail").then((m) => ({
    default: m.EnvironmentDetail,
  }))
);
const EnvironmentHome = lazy(() =>
  import("./Console/Environments/EnvironmentDetail").then((m) => ({
    default: m.EnvironmentHome,
  }))
);
const EnvironmentConfiguration = lazy(() =>
  import("./Console/Environments/EnvironmentConfiguration").then((m) => ({
    default: m.EnvironmentConfiguration,
  }))
);
const EnvironmentAccess = lazy(() =>
  import("./Console/Environments/EnvironmentAccess").then((m) => ({
    default: m.EnvironmentAccess,
  }))
);
const SelectOrg = lazy(() =>
  import("./Console/SelectOrg").then((m) => ({ default: m.SelectOrg }))
);
const MicrofrontendAbout = lazy(() =>
  import("./Console/Microfrontends/MicrofrontendAbout").then((m) => ({
    default: m.MicrofrontendAbout,
  }))
);
const OrgSettingsPage = lazy(() =>
  import("./Console/OrgSettings/OrgSettingsPage").then((m) => ({
    default: m.OrgSettingsPage,
  }))
);
const OrgSettingsConfiguration = lazy(() =>
  import("./Console/OrgSettings/OrgSettingsConfiguration").then((m) => ({
    default: m.OrgSettingsConfiguration,
  }))
);
const OrgSettingsSubscription = lazy(() =>
  import("./Console/OrgSettings/OrgSettingsSubscription").then((m) => ({
    default: m.OrgSettingsSubscription,
  }))
);
const OrgSettingsAccess = lazy(() =>
  import("./Console/OrgSettings/OrgSettingsAccess").then((m) => ({
    default: m.OrgSettingsConfiguration,
  }))
);
const OrgSettingsHome = lazy(() =>
  import("./Console/OrgSettings/OrgSettingsHome").then((m) => ({
    default: m.OrgSettingsHome,
  }))
);
const NotFound = lazy(() =>
  import("./NotFound").then((m) => ({
    default: m.NotFound,
  }))
);
const MarketingPage = lazy(() =>
  import("./Marketing/MarketingPage").then((m) => ({
    default: m.MarketingPage,
  }))
);
const MarketingHome = lazy(() =>
  import("./Marketing/MarketingHome").then((m) => ({
    default: m.MarketingHome,
  }))
);
const CreateEnvironment = lazy(() =>
  import("./Console/Environments/CreateEnvironment").then((m) => ({
    default: m.CreateEnvironment,
  }))
);
const CreateMicrofrontend = lazy(() =>
  import("./Console/Microfrontends/CreateMicrofrontend").then((m) => ({
    default: m.CreateMicrofrontend,
  }))
);
const UserProfile = lazy(() =>
  import("./Console/UserProfile/UserProfile").then((m) => ({
    default: m.UserProfile,
  }))
);
const ConsoleUnknownRoute = lazy(() =>
  import("./Console/ConsoleUnknownRoute").then((m) => ({
    default: m.ConsoleUnknownRoute,
  }))
);
const ConsolePageNotFound = lazy(() =>
  import("./Console/ConsolePageNotFound").then((m) => ({
    default: m.ConsolePageNotFound,
  }))
);
const UserProfileSecurity = lazy(() =>
  import("./Console/UserProfile/UserProfileSecurity").then((m) => ({
    default: m.UserProfileSecurity,
  }))
);
const RegisterAccount = lazy(() =>
  import("./Registration/RegisterAccount").then((m) => ({
    default: m.RegisterAccount,
  }))
);
const MailingList = lazy(() =>
  import("./Registration/MailingList").then((m) => ({
    default: m.MailingList,
  }))
);
const RequestDemo = lazy(() =>
  import("./Registration/RequestDemo").then((m) => ({
    default: m.RequestDemo,
  }))
);
const Changelog = lazy(() =>
  import("./Changelog/Changelog").then((m) => ({
    default: m.Changelog,
  }))
);
const WebAppsList = lazy(() =>
  import("./Console/WebApps/WebAppsList").then((m) => ({
    default: m.WebAppsList,
  }))
);
const WebAppDetail = lazy(() =>
  import("./Console/WebApps/WebAppDetail").then((m) => ({
    default: m.WebAppDetail,
  }))
);
const WebAppConfiguration = lazy(() =>
  import("./Console/WebApps/WebAppConfiguration").then((m) => ({
    default: m.WebAppConfiguration,
  }))
);
const WebAppHome = lazy(() =>
  import("./Console/WebApps/WebAppHome").then((m) => ({
    default: m.WebAppHome,
  }))
);
const CreateWebApp = lazy(() =>
  import("./Console/WebApps/CreateWebApp").then((m) => ({
    default: m.CreateWebApp,
  }))
);
const PrivacyPolicy = lazy(() =>
  import("./Marketing/PrivacyPolicy").then((m) => ({
    default: m.PrivacyPolicy,
  }))
);
const TermsAndConditions = lazy(() =>
  import("./Marketing/TermsAndConditions").then((m) => ({
    default: m.TermsAndConditions,
  }))
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

interface RootProps extends AppProps {
  accountNowEnabled(accountEnabled: boolean): any;
  customerOrgChanged(customerOrg: CustomerOrg): void;
}

export const RootPropsContext = createContext<RootProps>({
  reqUrl: "/",
  ssrResult: {
    ejsData: {
      pageTitle: "Baseplate",
    },
  },
  assetBase: "/static",
  webpackPublicPath: "",
  userInformation: {
    isLoggedIn: false,
    userPreferences: {
      auditAccountId: "",
      id: "",
      userId: "",
    },
  },
  accountNowEnabled() {},
  customerOrgChanged(customerOrg: CustomerOrg) {},
  httpInfo: {
    approximateScreenWidth: 768,
  },
});

export function App(props: AppProps) {
  const [pageLayout, setPageLayout] =
    useState<Omit<PageLayout, "setSecondaryNav">>(defaultPageLayout);
  const [rootProps, setRootProps] = useState<AppProps>(props);

  const Router = inBrowser ? BrowserRouter : StaticRouter;

  return (
    <QueryClientProvider client={queryClient}>
      <RootPropsContext.Provider
        value={{ ...rootProps, accountNowEnabled, customerOrgChanged }}
      >
        <Suspense
          fallback={
            <Router location={props.reqUrl}>
              <MainContent>
                <Loader description="Loading route" delay={100} />
              </MainContent>
            </Router>
          }
        >
          <Router location={props.reqUrl}>
            <Routes>
              <Route element={<BodyBackgroundColor />}>
                <Route path="register-account" element={<RegisterAccount />} />
                <Route path="mailing-list" element={<MailingList />} />
                <Route path="request-demo" element={<RequestDemo />} />
                <Route
                  path="/"
                  element={<MarketingPage />}
                  children={
                    <>
                      <Route path="/" element={<MarketingHome />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/reset-password"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="/reset-password-email-sent"
                        element={<ResetPasswordEmailSent />}
                      />
                      <Route
                        path="/finish-reset-password"
                        element={<FinishResetPassword />}
                      />
                      <Route
                        path="terms-and-conditions"
                        element={<TermsAndConditions />}
                      />
                      <Route
                        path="privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        path="/finish-account-creation"
                        element={<FinishAccountCreation />}
                      />
                      <Route path="pricing" element={<Pricing />} />
                    </>
                  }
                />
                <Route
                  path="/console/*"
                  element={<ConsolePage />}
                  children={
                    <>
                      {RouteWithCustomerOrgId({
                        pathSuffix: "microfrontends",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            pageName="Microfrontends List"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ViewAllMicrofrontendDeployments,
                            }}
                            entireMainContent
                          >
                            <MicrofrontendsList />
                          </PagePermissionWall>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "microfrontends/new",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            pageName="Create Microfrontend"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.CreateMicrofrontend,
                            }}
                            entireMainContent
                          >
                            <CreateMicrofrontend />
                          </PagePermissionWall>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "microfrontends/:microfrontendId",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            entireMainContent
                            pageName="Microfrontend Detail"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ViewAllMicrofrontendDeployments,
                            }}
                          >
                            <MicrofrontendDetail />
                          </PagePermissionWall>
                        ),
                        children: (
                          <>
                            <Route
                              path="deployments"
                              element={<MicrofrontendDeployments />}
                            />
                            <Route
                              path="access"
                              element={<MicrofrontendAccess />}
                            />
                            <Route
                              path="configuration"
                              element={<MicrofrontendConfiguration />}
                            />
                            <Route
                              path="about"
                              element={<MicrofrontendAbout />}
                            />
                            <Route path="" element={<MicrofrontendHome />} />
                          </>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "environments",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            entireMainContent
                            pageName="Environments List"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ViewAllEnvironments,
                            }}
                          >
                            <EnvironmentsList />
                          </PagePermissionWall>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "environments/new",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            entireMainContent
                            pageName="Create Environment"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ManageAllEnvironments,
                            }}
                          >
                            <CreateEnvironment />
                          </PagePermissionWall>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "environments/:environmentId",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            entireMainContent
                            pageName="Environment Detail"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ViewAllEnvironments,
                            }}
                          >
                            <EnvironmentDetail />
                          </PagePermissionWall>
                        ),
                        children: (
                          <>
                            <Route
                              path="configuration"
                              element={<EnvironmentConfiguration />}
                            />
                            <Route
                              path="access"
                              element={<EnvironmentAccess />}
                            />
                            <Route path="" element={<EnvironmentHome />} />
                          </>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "org-settings",
                        element: lazyMainContentRoute(<OrgSettingsPage />),
                        children: (
                          <>
                            <Route
                              path="access"
                              element={
                                <PagePermissionWall
                                  entireMainContent={false}
                                  pageName="Organization Access"
                                  requiredPermissions={{
                                    permission:
                                      BaseplatePermission.ViewCustomerOrgAccess,
                                  }}
                                >
                                  <OrgSettingsAccess />
                                </PagePermissionWall>
                              }
                            />
                            <Route
                              path="subscription"
                              element={
                                <PagePermissionWall
                                  entireMainContent={false}
                                  pageName="Organization Subscription"
                                  requiredPermissions={{
                                    permission:
                                      BaseplatePermission.ViewCustomerOrgBilling,
                                  }}
                                >
                                  <OrgSettingsSubscription />
                                </PagePermissionWall>
                              }
                            />
                            <Route
                              path="configuration"
                              element={
                                <PagePermissionWall
                                  entireMainContent={false}
                                  pageName="Organization Configuration"
                                  requiredPermissions={{
                                    permission:
                                      BaseplatePermission.ViewCustomerOrgSettings,
                                  }}
                                >
                                  <OrgSettingsConfiguration />
                                </PagePermissionWall>
                              }
                            />
                            <Route path="" element={<OrgSettingsHome />} />
                          </>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "web-apps",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            entireMainContent
                            pageName="Web Apps List"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ViewAllCustomerWebApps,
                            }}
                          >
                            <WebAppsList />
                          </PagePermissionWall>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "web-apps/new",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            pageName="Create WebApp"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ManageAllCustomerWebApps,
                            }}
                            entireMainContent
                          >
                            <CreateWebApp />
                          </PagePermissionWall>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "web-apps/:customerWebAppId",
                        element: lazyMainContentRoute(
                          <PagePermissionWall
                            entireMainContent
                            pageName="Web App Detail"
                            requiredPermissions={{
                              permission:
                                BaseplatePermission.ViewAllCustomerWebApps,
                            }}
                          >
                            <WebAppDetail />
                          </PagePermissionWall>
                        ),
                        children: (
                          <>
                            <Route
                              path="configuration"
                              element={
                                <PagePermissionWall
                                  entireMainContent={false}
                                  pageName="Web App Configuration"
                                  requiredPermissions={{
                                    permission:
                                      BaseplatePermission.ViewAllEnvironments,
                                  }}
                                >
                                  <WebAppConfiguration />
                                </PagePermissionWall>
                              }
                            />
                            {/* <Route path="about" element={<WebAppAbout />} /> */}
                            <Route path="" element={<WebAppHome />} />
                          </>
                        ),
                      })}
                      {RouteWithCustomerOrgId({
                        pathSuffix: "profile",
                        element: lazyMainContentRoute(<UserProfile />),
                        children: (
                          <>
                            <Route
                              path="security"
                              element={lazyMainContentRoute(
                                <UserProfileSecurity />
                              )}
                            />
                          </>
                        ),
                      })}
                      <Route
                        path=":customerOrgId"
                        element={lazyMainContentRoute(<ConsoleUnknownRoute />)}
                      />
                      <Route
                        path=":customerOrgId/*"
                        element={lazyMainContentRoute(<ConsolePageNotFound />)}
                      />
                    </>
                  }
                />
                <Route path="/console/select-org" element={<SelectOrg />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/docs" element={<Docs />}>
                  <Route path="" element={<DocsHome />} />
                  <Route path=":folder1/:docsPage" element={<DocsPage />} />
                </Route>
                <Route path="/changelog" element={<Changelog />} />
                <Route path="/logout" element={<Refresh />} />
              </Route>
            </Routes>
          </Router>
        </Suspense>
      </RootPropsContext.Provider>
    </QueryClientProvider>
  );

  function accountNowEnabled(accountEnabled: boolean) {
    setRootProps({
      ...rootProps,
      userInformation: {
        ...rootProps.userInformation,
        orgAccountEnabled: accountEnabled,
      },
    });
  }

  function customerOrgChanged(customerOrg: CustomerOrg) {
    setRootProps({
      ...rootProps,
      userInformation: {
        isLoggedIn: true,
        orgAccountEnabled: customerOrg.accountEnabled,
        orgId: customerOrg.id,
        orgKey: customerOrg.orgKey,
        userPreferences: rootProps.userInformation.userPreferences,
      },
    });
  }
}

export interface AppProps {
  userInformation: UserInformation;
  ssrResult: SSRResult;
  reqUrl: string;
  assetBase: string;
  webpackPublicPath: string;
  baseplateAccount?: BaseplateAccount;
  httpInfo: {
    approximateScreenWidth: number;
  };
}

export interface UserInformation {
  isLoggedIn: boolean;
  orgKey?: string;
  orgId?: BaseplateUUID;
  orgAccountEnabled?: boolean;
  userPreferences?: UserPreferencesAttributes;
}

export interface SSRResult {
  redirectUrl?: string;
  ejsData: EJSData;
}

export interface EJSData {
  pageTitle: string | Promise<string>;
}

interface SecondaryNavLayout {
  present: boolean;
  orientation: NavOrientation;
}

export interface PageLayout {
  setSecondaryNav(newLayout: SecondaryNavLayout): void;
  secondaryNav: SecondaryNavLayout;
}

export enum NavOrientation {
  vertical = "vertical",
  horizontal = "horizontal",
}

const defaultPageLayout: Omit<PageLayout, "setSecondaryNav"> = {
  secondaryNav: {
    present: false,
    orientation: NavOrientation.horizontal,
  },
};

function Refresh() {
  useEffect(() => {
    location.reload();
  });

  return null;
}
