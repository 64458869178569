import { HTMLProps, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Button, ButtonKind } from "./Button";
import { Icon, IconVariant } from "./Icon";
import { always } from "kremling";

export function Modal(props: ModalProps) {
  const [containerEl, setContainerEl] = useState<HTMLElement | void>();

  useEffect(() => {
    const el = document.createElement("div");
    document.body.appendChild(el);

    setContainerEl(el);

    return () => {
      el.remove();
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    document.body.classList.add("h-full");
    return () => {
      document.body.classList.remove("overflow-hidden");
      document.body.classList.remove("h-full");
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", hotKeys);

    return () => {
      window.removeEventListener("keyup", hotKeys);
    };

    function hotKeys(evt: KeyboardEvent) {
      if (evt.key === "Escape") {
        props.close();
      }
    }
  });

  if (!containerEl) {
    return null;
  }

  return createPortal(
    <>
      {/* modal screen / overlay */}
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"></div>
      {/* dialog / content */}
      <ModalDialog {...props} />
    </>,
    containerEl
  );
}

// Exported for use in storybookjs documentation
export function ModalDialog(props: ModalProps) {
  return (
    <dialog
      className={always(
        "fixed top-28 mx-auto border shadow-lg rounded-md bg-white p-0 max-h-[90vh] overflow-y-auto text-darkNavy"
      ).toggle("w-10/12", "w-[640px]", props.width === "wide")}
      open
    >
      <header className="pb-[30px] pt-[60px] px-[50px]">
        <h1 className="text-[1.625rem]">{props.title}</h1>
        <Button
          type="button"
          kind={ButtonKind.icon}
          onClick={props.close}
          className="absolute top-[20px] right-[20px]"
        >
          <Icon
            variant={IconVariant.close}
            alt="Close Modal Icon"
            className="text-black"
          />
        </Button>
      </header>
      <div className="px-[3.125rem] pb-[3.75rem]">{props.children}</div>
    </dialog>
  );
}

export function ModalActions(props: ModalActionsProps) {
  return (
    <div className="flex w-full gap-[1.875rem] mt-6">
      <Button
        kind={ButtonKind.secondary}
        type="button"
        onClick={props.cancel}
        className="grow"
        disabled={props.disabled ?? false}
      >
        {props.cancelText ?? "Cancel"}
      </Button>
      {props.submitElement ?? (
        <Button
          kind={ButtonKind.primary}
          type="submit"
          onClick={props.submit}
          disabled={props.disabled ?? false}
          className="grow"
        >
          {props.submitText ?? "Save"}
        </Button>
      )}
    </div>
  );
}

interface ModalActionsProps {
  cancelText?: string;
  cancel(val?: unknown): unknown;
  submitText?: string;
  submit?(): unknown;
  submitElement?: ReactNode;
  disabled?: boolean;
}

export interface ModalProps extends HTMLProps<HTMLDialogElement> {
  title: string;
  close(): any;
  width?: "narrow" | "wide";
}

export interface ModalSubmitProps {
  closeModal: (shouldRefetch: boolean) => any;
  disableButtons?: boolean;
}
