import { createElement, HTMLProps, useContext } from "react";
import { always } from "kremling";
import { FormContext, FormOrientation } from "./Form";

export function FormField(props: FormFieldProps) {
  const { className = "", children, useFieldset, ...otherProps } = props;
  const formContext = useContext(FormContext);
  const orientation = props.orientation ?? formContext.orientation;

  const elementName = useFieldset ? "fieldset" : "div";

  return createElement(
    elementName,
    {
      ...otherProps,
      className: always(className)
        .always("flex")
        .toggle(
          "flex-col",
          "flex-row",
          orientation === FormOrientation.vertical
        )
        .toString(),
    },
    children
  );
}

export interface FormFieldProps extends HTMLProps<HTMLDivElement> {
  useFieldset?: boolean;
  orientation?: FormOrientation;
}
