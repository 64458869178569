import { Suspense, ReactElement } from "react";
import { Loader } from "../Styleguide/Loader";
import { MainContent } from "../Styleguide/MainContent";

export function lazyMainContentRoute(element: ReactElement): ReactElement {
  return (
    <Suspense
      fallback={
        <MainContent>
          <Loader description="Loading route" delay={100} />
        </MainContent>
      }
    >
      {element}
    </Suspense>
  );
}
