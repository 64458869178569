import { Link, LinkProps } from "react-router-dom";
import {
  buttonClasses,
  ButtonKind,
  ButtonNotches,
  ButtonNotchType,
  ButtonSize,
} from "./Button";
import { forwardRef } from "react";

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    const {
      kind,
      className,
      buttonSize = ButtonSize.large,
      children,
      to,
      notchType = ButtonNotchType.none,
      notchBackground,
      ...otherProps
    } = props;

    // Use react-router (no page reload) when "to" prop is passed
    // Use <a> (with page reload) when "href" prop is passed
    const useReactRouter = !Boolean(props.href);
    const hasNotches = notchType !== ButtonNotchType.none;

    if (useReactRouter) {
      const to: string = props.to as string;

      return (
        <Link
          ref={ref}
          to={to}
          className={buttonClasses({
            kind,
            buttonSize,
            className,
            disabled: false,
            hasNotches,
          })}
          {...otherProps}
        >
          {children}
          {ButtonNotches({ notchType, kind, notchBackground })}
        </Link>
      );
    } else {
      return (
        <a
          className={buttonClasses({
            kind,
            buttonSize,
            className,
            disabled: false,
            hasNotches,
          })}
          {...otherProps}
          ref={ref}
        >
          {children}
          {ButtonNotches({ notchType, kind, notchBackground })}
        </a>
      );
    }
  }
);

export type AnchorProps = Omit<LinkProps, "to"> & {
  kind: ButtonKind;
  buttonSize?: ButtonSize;
  to?: string;
  href?: string;
  notchType?: ButtonNotchType;
  notchBackground?: string;
};
