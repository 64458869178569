import { ReactElement, ReactNode, useContext } from "react";
import {
  BaseplatePermission,
  findMissingPermissions,
  RequiredPermissions,
} from "../../backend/Utils/IsomorphicPermissions";
import { RootPropsContext } from "../App";
import { Card, CardHeader } from "../Styleguide/Card";
import { MainContent } from "../Styleguide/MainContent";
import { useConsoleParams } from "./paramHelpers";

export function PagePermissionWall(
  props: PagePermissionWallProps
): ReactElement {
  const { missingPermissions, hasPermission } = useHasPermission(
    props.requiredPermissions
  );

  if (hasPermission) {
    return <>{props.children}</>;
  } else {
    const content = (
      <div>
        The following permission issues must be resolved to view the{" "}
        {props.pageName} page:
        <ul className="ml-8 mt-2 text-sm list-disc">
          {missingPermissions.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </ul>
      </div>
    );

    if (props.entireMainContent) {
      return (
        <MainContent>
          <Card
            header={
              <CardHeader className="text-lg font-semibold">
                Not Authorized
              </CardHeader>
            }
          >
            {content}
          </Card>
        </MainContent>
      );
    } else {
      return content;
    }
  }
}

export function useHasPermission(
  requiredPermissions: RequiredPermissions
): HasPermissionResult {
  const { customerOrgId } = useConsoleParams();
  const rootProps = useContext(RootPropsContext);

  if (!rootProps.baseplateAccount) {
    throw Error(
      "Cannot determine missing permissions because user isn't logged in"
    );
  }

  const missingPermissions = findMissingPermissions(
    rootProps.baseplateAccount.accountPermissions,
    customerOrgId,
    requiredPermissions
  );

  return {
    missingPermissions,
    hasPermission: missingPermissions.length === 0,
  };
}

export interface HasPermissionResult {
  hasPermission: boolean;
  missingPermissions: string[];
}

export interface PagePermissionWallProps {
  entireMainContent: boolean;
  pageName: string;
  requiredPermissions: RequiredPermissions;
  children: ReactNode | ReactNode[];
}
