import { hydrateRoot } from "react-dom/client";
import * as React from "react";
import { App, AppProps } from "./App";
import "tailwindcss/tailwind.css";

const rootProps = JSON.parse(
  (document.querySelector("script#root-props") as HTMLElement)
    .textContent as string
) as AppProps;

__webpack_public_path__ = rootProps.webpackPublicPath;

const root = hydrateRoot(
  document.getElementById("react-root") as HTMLDivElement,
  <App {...rootProps} />,
  {
    onRecoverableError(err) {
      console.log("recoverable error", err);
    },
  }
);
