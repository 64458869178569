import { AccountPermissionModel } from "../DB/Models/IAM/AccountPermission";
import {
  BaseplateUUID,
  ModelWithIncludes,
} from "../DB/Models/SequelizeTSHelpers";
import { PermissionModel } from "../DB/Models/IAM/Permission";

export function findMissingPermissions(
  accountPermissions: ModelWithIncludes<
    AccountPermissionModel,
    { permission: PermissionModel }
  >[],
  customerOrgId: BaseplateUUID,
  requiredPermissions: RequiredPermissions
): string[] {
  const missingPermissions: string[] = [];

  recurseRequiredPermissions(requiredPermissions, missingPermissions);

  return missingPermissions;

  function recurseRequiredPermissions(
    thisPermission: RequiredPermissions,
    missingPermissions: string[]
  ): void {
    if (isSimplePermission(thisPermission)) {
      const p = thisPermission as SimpleRequiredPermission;
      const hasPermission = accountPermissions.some((accountP) => {
        const permissionNameMatches = p.permission === accountP.permission.name;
        const customerOrgMatches = accountP.customerOrgId === customerOrgId;
        const entityIdMatches =
          !accountP.permission.requiresEntityId ||
          accountP.entityId === p.entityId;
        const isRevoked = accountP.dateRevoked
          ? accountP.dateRevoked < new Date()
          : false;

        return (
          permissionNameMatches &&
          customerOrgMatches &&
          entityIdMatches &&
          !isRevoked
        );
      });

      if (!hasPermission) {
        missingPermissions.push(`Missing permission ${p.permission}`);
      }
    } else {
      const p = thisPermission as CompoundRequiredPermission;
      if (p.operator === PermissionOperator.and) {
        p.permissionList.forEach((childPermission) => {
          recurseRequiredPermissions(childPermission, missingPermissions);
        });
      } else if (p.operator === PermissionOperator.or) {
        const atLeastOnePermission = p.permissionList.some(
          (childPermission) => {
            const childMissingPermissions: string[] = [];
            recurseRequiredPermissions(
              childPermission,
              childMissingPermissions
            );
            return childMissingPermissions.length === 0;
          }
        );

        if (!atLeastOnePermission) {
          missingPermissions.push(
            `Missing one of the following permissions: ${p.permissionList
              .map((per) =>
                isSimplePermission(per)
                  ? (per as SimpleRequiredPermission).permission
                  : "[compound permission]"
              )
              .join(", ")}`
          );
        }
      } else {
        throw Error(`Unknown PermissionOperator '${p.operator}'`);
      }
    }
  }
}

function isSimplePermission(requiredPermission: RequiredPermissions) {
  return Boolean((requiredPermission as SimpleRequiredPermission)?.permission);
}

export type RequiredPermissions =
  | SimpleRequiredPermission
  | CompoundRequiredPermission;

export interface SimpleRequiredPermission {
  permission: BaseplatePermission;
  entityId?: BaseplateUUID;
}

export interface CompoundRequiredPermission {
  permissionList: (SimpleRequiredPermission | RequiredPermissions)[];
  operator: PermissionOperator;
}

export enum PermissionOperator {
  or = "or",
  and = "and",
}

export enum BaseplatePermission {
  ViewCustomerOrgSettings = "customerOrgs.settings.view",
  ManageCustomerOrgSettings = "customerOrgs.settings.manage",
  ViewCustomerOrgBilling = "customerOrgs.billing.view",
  ViewCustomerOrgAccess = "customerOrgs.access.view",
  ManageCustomerOrgAccess = "customerOrgs.access.manage",
  ManageCustomerOrgOwner = "customerOrgs.owner.manage",
  ManageAllMicrofrontendOwners = "allMicrofrontends.owner.manage",
  DeployAllMicrofrontends = "allMicrofrontends.deployments.trigger",
  ViewAllMicrofrontendDeployments = "allMicrofrontends.deployments.view",
  ViewAllMicrofrontendAccess = "allMicrofrontends.access.view",
  ManageAllMicrofrontendAccess = "allMicrofrontends.access.manage",
  CreateMicrofrontend = "allMicrofrontends.create",
  ManageAllMicrofrontendSettings = "allMicrofrontends.settings.manage",
  ManageOneMicrofrontendOwner = "microfrontend.owner.manage",
  DeployOneMicrofrontend = "microfrontend.deployments.trigger",
  ManageOneMicrofrontendAccess = "microfrontend.access.manage",
  ManageOneMicrofrontendSettings = "microfrontend.settings.manage",
  ViewAllEnvironments = "allEnvironments.view",
  ManageAllEnvironments = "allEnvironments.manage",
  ViewAllCustomerWebApps = "allCustomerWebApps.view",
  ManageAllCustomerWebApps = "allCustomerWebApps.manage",
}

export function permissionToShortReadableVerb(
  permission: BaseplatePermission
): string {
  switch (permission) {
    case BaseplatePermission.ViewCustomerOrgSettings:
      return "View Settings";
    case BaseplatePermission.ManageCustomerOrgSettings:
      return "Manage Access";
    case BaseplatePermission.ViewCustomerOrgBilling:
      return "View Billing";
    case BaseplatePermission.ViewCustomerOrgAccess:
      return "View Access";
    case BaseplatePermission.ManageCustomerOrgAccess:
      return "Manage Access";
    case BaseplatePermission.ManageCustomerOrgOwner:
      return "Manage Owner";
    case BaseplatePermission.ManageAllMicrofrontendOwners:
      return "Manage Owner";
    case BaseplatePermission.DeployAllMicrofrontends:
      return "Deploy";
    case BaseplatePermission.ViewAllMicrofrontendDeployments:
      return "View Deploys";
    case BaseplatePermission.ViewAllMicrofrontendAccess:
      return "View Access";
    case BaseplatePermission.ManageAllMicrofrontendAccess:
      return "Manage Access";
    case BaseplatePermission.CreateMicrofrontend:
      return "Create Microfrontend";
    case BaseplatePermission.ManageAllMicrofrontendSettings:
      return "Manage Settings";
    case BaseplatePermission.ManageOneMicrofrontendOwner:
      return "Manage Owner";
    case BaseplatePermission.ManageOneMicrofrontendAccess:
      return "Manage Access";
    case BaseplatePermission.DeployOneMicrofrontend:
      return "Deploy";
    case BaseplatePermission.ManageOneMicrofrontendSettings:
      return "Manage Settings";
    case BaseplatePermission.ViewAllEnvironments:
      return "View Environments";
    case BaseplatePermission.ManageAllEnvironments:
      return "Manage Environments";
    case BaseplatePermission.ViewAllCustomerWebApps:
      return "View Web Apps";
    case BaseplatePermission.ManageAllCustomerWebApps:
      return "Manage Web Apps";
    default:
      throw Error(
        `No short readable verb implemented for permission '${permission}'`
      );
  }
}

export function permissionRequiredToModifyPermission(
  permission: BaseplatePermission
): BaseplatePermission[] {
  switch (permission) {
    case BaseplatePermission.ViewCustomerOrgSettings:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.ManageCustomerOrgSettings:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.ViewCustomerOrgBilling:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.ViewCustomerOrgAccess:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.ManageCustomerOrgAccess:
      return [BaseplatePermission.ManageCustomerOrgOwner];
    case BaseplatePermission.ManageCustomerOrgOwner:
      return [BaseplatePermission.ManageCustomerOrgOwner];
    case BaseplatePermission.ManageAllMicrofrontendOwners:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.DeployAllMicrofrontends:
      return [BaseplatePermission.ManageAllMicrofrontendAccess];
    case BaseplatePermission.ViewAllMicrofrontendDeployments:
      return [BaseplatePermission.ManageAllMicrofrontendAccess];
    case BaseplatePermission.ViewAllMicrofrontendAccess:
      return [BaseplatePermission.ManageAllMicrofrontendAccess];
    case BaseplatePermission.ManageAllMicrofrontendAccess:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.CreateMicrofrontend:
      return [BaseplatePermission.ManageAllMicrofrontendAccess];
    case BaseplatePermission.ManageAllMicrofrontendSettings:
      return [BaseplatePermission.ManageAllMicrofrontendAccess];
    case BaseplatePermission.ManageOneMicrofrontendOwner:
      return [
        BaseplatePermission.ManageOneMicrofrontendOwner,
        BaseplatePermission.ManageAllMicrofrontendOwners,
      ];
    case BaseplatePermission.ManageOneMicrofrontendAccess:
      return [
        BaseplatePermission.ManageOneMicrofrontendAccess,
        BaseplatePermission.ManageAllMicrofrontendAccess,
      ];
    case BaseplatePermission.DeployOneMicrofrontend:
      return [
        BaseplatePermission.ManageOneMicrofrontendAccess,
        BaseplatePermission.ManageAllMicrofrontendAccess,
      ];
    case BaseplatePermission.ManageOneMicrofrontendSettings:
      return [
        BaseplatePermission.ManageOneMicrofrontendAccess,
        BaseplatePermission.ManageAllMicrofrontendAccess,
      ];
    case BaseplatePermission.ViewAllEnvironments:
      return [BaseplatePermission.ManageAllEnvironments];
    case BaseplatePermission.ManageAllEnvironments:
      return [BaseplatePermission.ManageCustomerOrgAccess];
    case BaseplatePermission.ViewAllCustomerWebApps:
    case BaseplatePermission.ManageAllCustomerWebApps:
      return [BaseplatePermission.ManageAllCustomerWebApps];
    default:
      throw Error(`Permission change not implemented for '${permission}'`);
  }
}

export enum AccountRoleName {
  DeployAllMFEs = "allMicrofrontends.deployments",
  OrgOwner = "customerOrgs.owner",
  MFEOwner = "microfrontend.owner",
  Developer = "developer",
}
