import { useEffect } from "react";
import { Outlet, useLocation } from "react-router";
import { bodyElementIsGray } from "../../backend/WebApp/bodyBackgroundUtils";

export function BodyBackgroundColor() {
  const location = useLocation();

  useEffect(() => {
    const isGray = bodyElementIsGray(location.pathname);
    const bodyElement = document.querySelector("body")!;
    bodyElement.classList.toggle("bg-white", !isGray);
    bodyElement.classList.toggle("bg-gray-100", isGray);
  });

  return <Outlet />;
}
