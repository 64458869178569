import { ButtonKind } from "./Button";
import { Anchor } from "./Anchor";
import { Icon, IconVariant } from "./Icon";
import { useConsoleParams } from "../Utils/paramHelpers";
import { useLocation } from "react-router";
import { useAssetUrls } from "../Utils/assetUrls";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuLink,
  MenuPopover,
} from "./MenuButton";
import { positionRight } from "@reach/popover";

export function ConsolePrimaryNav(props: Props) {
  const { customerOrgId } = useConsoleParams();
  const location = useLocation();
  const [logoUrl] = useAssetUrls("/static/baseplate-logo-white.png");

  return (
    <nav
      className={`fixed top-0 left-0 h-16 w-screen text-white`}
      style={{
        background:
          "transparent radial-gradient(closest-side at 0% 0%, #1A2E61CC 0%, #1A2E61 100%) 0% 0% no-repeat padding-box",
      }}
    >
      <div className="flex justify-between items-center h-full">
        <section className="h-full flex items-center justify-between">
          <Anchor
            kind={ButtonKind.transparent}
            to="/"
            className="w-[128px] ml-[30px]"
          >
            <img alt="baseplate logo" src={logoUrl} />
          </Anchor>
        </section>
        <section className="lg:mr-4 mr-14 flex justify-end items-center font-extralight mr-narrowConsole lg:mr-wideConsole">
          <Anchor
            kind={ButtonKind.transparent}
            to="mailto:support@baseplate.cloud"
            className="text-[19px]"
          >
            Contact Support
          </Anchor>
          {location.pathname.startsWith("/docs") ? (
            <Anchor
              kind={ButtonKind.transparent}
              to="/console"
              className="text-[19px] ml-[50px]"
            >
              Console
            </Anchor>
          ) : (
            <Anchor
              kind={ButtonKind.transparent}
              to="/docs"
              className="text-[19px] ml-[50px]"
            >
              Documentation
            </Anchor>
          )}
          <Menu>
            <MenuButton kind={ButtonKind.transparent} className="ml-[3.125rem]">
              <Icon
                className="text-white"
                size={22}
                variant={IconVariant.user}
                alt="user profile icon"
                fill="white"
              />
            </MenuButton>
            <MenuPopover position={positionRight}>
              <MenuItems>
                <MenuLink to={`/console/${customerOrgId}/profile`}>
                  User Profile
                </MenuLink>
                <MenuLink href={`/logout`}>Log out</MenuLink>
              </MenuItems>
            </MenuPopover>
          </Menu>
        </section>
      </div>
    </nav>
  );
}

interface Props {}
