import { always, maybe } from "kremling";
import {
  createElement,
  forwardRef,
  Fragment,
  HTMLProps,
  ReactNode,
} from "react";

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  props,
  ref
) {
  const {
    className,
    contentPadding = true,
    footer,
    children,
    header,
    contentProps,
    notches,
    notchContainerClassName = "",
    ...otherProps
  } = props;
  const { className: contentClassName, ...otherContentProps } =
    contentProps || {};

  return createElement(
    notches ? "div" : Fragment,
    // @ts-ignore
    notches ? { className: notchContainerClassName } : {},
    <>
      {notches && (
        <div className="flex h-5">
          <div className="h-5 w-1/5 bg-white"> </div>
          <div className="h-5 w-2/5 bg-transparent"> </div>
          <div className="h-5 w-2/5 bg-white"> </div>
        </div>
      )}
      <div
        ref={ref}
        className={always(className as string)
          .always("bg-white")
          .toggle("pt-1.5", "py-1.5", footer || !contentPadding)
          .toString()}
        {...otherProps}
      >
        {header}
        <div
          className={maybe("px-3.5", contentPadding).always(
            contentClassName as string
          )}
          {...otherContentProps}
        >
          {children}
        </div>
        {footer}
      </div>
      {notches && (
        <div className="flex h-5">
          <div className="h-5 w-3/5 bg-white"> </div>
          <div className="h-5 w-1/5 bg-transparent"> </div>
          <div className="h-5 w-1/5 bg-white"> </div>
        </div>
      )}
    </>
  );
});

export interface CardProps extends HTMLProps<HTMLDivElement> {
  footer?: ReactNode;
  header?: ReactNode;
  contentProps?: HTMLProps<HTMLDivElement>;
  contentPadding?: boolean;
  notches?: boolean;
  notchContainerClassName?: string;
}

export function CardFooter(props: CardFooterProps) {
  const { className, children, ...otherProps } = props;

  return (
    <div
      className={always(className as string)
        .always("border-t border-gray-300 mt-1.5 py-1.5 px-3.5")
        .toString()}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export interface CardFooterProps extends HTMLProps<HTMLDivElement> {}

export function CardHeader(props: CardHeaderProps) {
  const { className, contentPadding = true, children, ...otherProps } = props;

  return (
    <div
      className={always(className as string)
        .always("border-b border-gray-300")
        .maybe("pt-2.5 px-3.5 pb-1.5", contentPadding)
        .toString()}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export interface CardHeaderProps extends HTMLProps<HTMLDivElement> {
  contentPadding?: boolean;
}
