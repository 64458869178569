import { useContext, useEffect, useState } from "react";
import { EndpointGetStripeBillingSessionResBody } from "../../../backend/RestAPI/Subscription/GetStripeBillingSession";
import { Button, ButtonKind } from "../../Styleguide/Button";
import { Card, CardHeader } from "../../Styleguide/Card";
import { baseplateFetch } from "../../Utils/baseplateFetch";
import { useConsoleParams } from "../../Utils/paramHelpers";
import { Loader } from "../../Styleguide/Loader";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  EndpointGetSalesPackagesResBody,
  SalesPackageWithProducts,
} from "../../../backend/RestAPI/Subscription/GetSalesPackages";
import { RootPropsContext } from "../../App";
import {
  EndpointUpdateSalesPackageReqBody,
  EndpointUpdateSalesPackageResBody,
} from "../../../backend/RestAPI/Subscription/UpdateSalesPackage";
import { BaseplateUUID } from "../../../backend/DB/Models/SequelizeTSHelpers";
import { always } from "kremling";
import { Modal } from "../../Styleguide/Modal";
import { Icon, IconKind, IconVariant } from "../../Styleguide/Icon";
import { Anchor } from "../../Styleguide/Anchor";

export function OrgSettingsSubscription() {
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(
    SessionStatus.waiting
  );
  const { customerOrgId } = useConsoleParams();
  const rootProps = useContext(RootPropsContext);
  const queryClient = useQueryClient();
  const [modalPlan, setModalPlan] = useState<SalesPackageWithProducts | null>(
    null
  );

  const salesPackagesQuery = useSalesPackagesQuery();

  const selectPlanMutation = useMutation<
    EndpointUpdateSalesPackageResBody,
    Error,
    BaseplateUUID
  >({
    mutationFn: async (salesPackageId: BaseplateUUID) => {
      await baseplateFetch<
        EndpointUpdateSalesPackageResBody,
        EndpointUpdateSalesPackageReqBody
      >(`/api/orgs/${customerOrgId}/sales-packages`, {
        method: "PUT",
        body: {
          salesPackageId,
        },
      });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`sales-packages-${customerOrgId}`],
        }),
        queryClient.invalidateQueries({
          queryKey: ["user-customer-orgs"],
        }),
      ]);
      rootProps.accountNowEnabled(true);
      setModalPlan(null);
    },
  });

  useEffect(() => {
    if (sessionStatus === SessionStatus.loadingSession) {
      baseplateFetch<EndpointGetStripeBillingSessionResBody>(
        `/api/orgs/${customerOrgId}/stripe-billing-sessions`
      )
        .then((json) => {
          window.location.assign(json.sessionUrl);
        })
        .catch((err) => {
          console.error(err);
          setSessionStatus(SessionStatus.error);
        });
    }
  }, [sessionStatus, customerOrgId]);

  const pricingTableColumns =
    salesPackagesQuery.data?.salesPackages.map(
      (salesPackage) => salesPackage.package.name
    ) ?? [];
  const selectedColumn =
    salesPackagesQuery.data?.salesPackages.findIndex(
      (salesPackage) => salesPackage.selectedForCustomerOrg
    ) ?? -1;
  const pricingTableRowNames: Set<string> = new Set<string>();

  const pricingTableRowData =
    salesPackagesQuery.data?.salesPackages.reduce<
      Record<string, SalesPackageWithProducts["stripeProducts"]>
    >((result, salesPackage, columnIndex) => {
      salesPackage.stripeProducts.forEach((stripeProduct) => {
        const dashIndex = stripeProduct.name.indexOf(" -");
        const productName = stripeProduct.name.slice(
          0,
          dashIndex > 0 ? dashIndex : undefined
        );
        pricingTableRowNames.add(productName);
        if (!result[productName]) {
          result[productName] = Array(pricingTableColumns.length).fill(null);
        }
        result[productName][columnIndex] = stripeProduct;
      });
      return result;
    }, {}) || {};

  const sortedRowNames = Array.from(pricingTableRowNames).sort((p1, p2) => {
    if (p1.includes("Flat")) {
      return -1;
    } else if (p2.includes("Flat")) {
      return 1;
    } else {
      return p1 > p2 ? 1 : -1;
    }
  });

  return (
    <div>
      {rootProps.userInformation.orgAccountEnabled ? (
        <span className="text-darkNavy font-light">
          Your Baseplate subscription is active. You will be invoiced and
          charged automatically once a month.
        </span>
      ) : (
        <>
          <span className="text-brick font-light">
            <Icon
              className="inline"
              variant={IconVariant.exclamationTriangle}
              kind={IconKind.solid}
            />{" "}
            Your Baseplate account is locked.
          </span>
          <span className="text-brick font-light">
            {" "}
            Please select a subscription and provide your payment information in
            the Stripe Billing portal.
          </span>
        </>
      )}
      <div className="border-t border-gray-200 mt-[1.875rem] py-[1.875rem] pb-[2.5rem]">
        {sessionStatus === SessionStatus.waiting && (
          <Button
            kind={ButtonKind.classic}
            onClick={manageBilling}
            className="text-consoleBase"
          >
            Billing Portal
          </Button>
        )}
      </div>
      {sessionStatus === SessionStatus.loadingSession && (
        <Loader description="Generating Stripe Session URL" />
      )}
      {sessionStatus === SessionStatus.error && (
        <div className="text-red-600">
          Error logging into Stripe. Please contact Baseplate customer support.
        </div>
      )}

      <h2 className="text-[1.625rem] font-medium text-darkNavy mb-[1.25rem]">
        Baseplate Subscription
      </h2>
      <p className="text-consoleBase mb-4 text-darkNavy font-light">
        All Baseplate subscriptions are invoiced and automatically charged on a
        monthly basis. The first Flat Fee is charged when you subscribe, and
        subsequent months are billed with usage-based fees.
      </p>
      <p className="text-consoleBase my-2 text-darkNavy font-light">
        For an enterprise quote, please{" "}
        <Anchor
          kind={ButtonKind.classic}
          href="mailto:sales@baseplate.cloud"
          target="_blank"
        >
          contact sales
        </Anchor>
        .
      </p>
      <p className="text-consoleBase mb-4 text-darkNavy font-light">
        All prices are USD and charged monthly.
      </p>
      {salesPackagesQuery.isLoading && (
        <Loader description="Loading sales packages" />
      )}
      {salesPackagesQuery.isError && (
        <div className="text-red-400">
          Error loading Baseplate sales packages
        </div>
      )}
      {salesPackagesQuery.data && (
        <table className="w-full my-8">
          <thead>
            <tr>
              <th style={{ width: "40%" }} />
              {pricingTableColumns.map((column, i) => (
                <th
                  key={column}
                  style={{ width: `${60 / pricingTableColumns.length}%` }}
                  className={always(
                    "py-2 px-2 font-light text-darkNavy text-consoleBase text-left"
                  ).maybe("text-white bg-primary", i === selectedColumn)}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-darkNavy text-consoleBase font-light">
            {sortedRowNames.map((rowName) => (
              <tr key={rowName}>
                <th className="p-2 text-left font-light border-b">
                  {rowHeader(rowName)}
                </th>
                {pricingTableRowData[rowName].map((stripeProduct, i) => (
                  <td
                    key={i}
                    className={always(
                      "text-consoleBase font-light text-darkNavy py-2 border-b"
                    ).maybe(
                      "bg-gray-100 border-gray-300",
                      i !== selectedColumn
                    )}
                  >
                    {humanReadableCents(
                      stripeProduct.price.unit_amount as number
                    )}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="border-b">
              <th className="py-2 font-light text-left">Dedicated support</th>
              {salesPackagesQuery.data.salesPackages.map((salesPackage, i) => (
                <td
                  key={salesPackage.package.id}
                  className={always("py-2").maybe(
                    "bg-gray-100",
                    i !== selectedColumn
                  )}
                >
                  {i === salesPackagesQuery.data.salesPackages.length - 1 ? (
                    "Priority"
                  ) : (
                    <Icon variant={IconVariant.check} />
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <td />
              {salesPackagesQuery.data.salesPackages.map((salesPackage, i) => (
                <td
                  key={salesPackage.package.id}
                  className={always("py-2").maybe(
                    "bg-gray-100",
                    i !== selectedColumn
                  )}
                >
                  <Button
                    kind={ButtonKind.classic}
                    onClick={() => setModalPlan(salesPackage)}
                  >
                    {salesPackage.selectedForCustomerOrg ? "Cancel" : "Select"}
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      )}
      {modalPlan && (
        <Modal
          close={() => setModalPlan(null)}
          title="Change Baseplate Subscription"
        >
          {!salesPackagesQuery.data!.stripePaymentSourceExists && (
            <>
              <p className="mb-4">
                Before choosing a Baseplate Package, please add a payment source
                in the Billing Portal.
              </p>
              <Button
                kind={ButtonKind.primary}
                onClick={() => setModalPlan(null)}
              >
                Done
              </Button>
            </>
          )}
          {salesPackagesQuery.data!.stripePaymentSourceExists &&
            modalPlan.selectedForCustomerOrg && (
              <>
                <p className="mb-4">
                  To cancel your Baseplate subscription, please contact
                  sales@baseplate.cloud.
                </p>
                <Button
                  kind={ButtonKind.primary}
                  onClick={() => setModalPlan(null)}
                >
                  Done
                </Button>
              </>
            )}
          {salesPackagesQuery.data!.stripePaymentSourceExists &&
            !modalPlan.selectedForCustomerOrg && (
              <>
                <p className="mb-4">
                  Are you sure you want to start a Baseplate{" "}
                  {modalPlan.package.name} subscription?{" "}
                  {rootProps.userInformation.orgAccountEnabled
                    ? "The change to your plan will be reflected in the invoice at the end of your billing cycle."
                    : "You will be charged for this plan monthly, including a Flat Fee charge today."}
                </p>
                <Button
                  kind={ButtonKind.primary}
                  onClick={() =>
                    selectPlanMutation.mutate(modalPlan.package.id)
                  }
                  disabled={selectPlanMutation.isPending}
                >
                  Subscribe
                </Button>
              </>
            )}
        </Modal>
      )}
    </div>
  );

  function manageBilling() {
    setSessionStatus(SessionStatus.loadingSession);
  }

  function rowHeader(rowName: string) {
    const stripeProduct = pricingTableRowData[rowName][0];
    if (!stripeProduct) {
      return rowName;
    }

    if (stripeProduct.price.transform_quantity?.divide_by) {
      const quantity =
        stripeProduct.price.transform_quantity.divide_by === 1000000
          ? "million"
          : stripeProduct.price.transform_quantity!.toLocaleString();
      return `Each ${quantity} ${stripeProduct.unit_label}s`;
    }

    if (stripeProduct.unit_label) {
      return `Each ${stripeProduct.unit_label}`;
    }

    return rowName;
  }
}

enum SessionStatus {
  waiting = "waiting",
  loadingSession = "loadingSession",
  error = "error",
}

function humanReadableCents(cents: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(cents / 100).replace(".00", "");
}

export function useSalesPackagesQuery() {
  const { customerOrgId } = useConsoleParams();
  const rootProps = useContext(RootPropsContext);

  return useQuery<unknown, Error, EndpointGetSalesPackagesResBody>({
    queryKey: [`sales-packages-${customerOrgId}`],
    queryFn: async () => {
      if (customerOrgId) {
        return await baseplateFetch<EndpointGetSalesPackagesResBody>(
          `/api/orgs/${customerOrgId}/sales-packages`,
          undefined,
          rootProps
        );
      } else {
        return new Promise<EndpointGetSalesPackagesResBody>((resolve) => {
          // Resolve with sample sales package with subscription active, to avoid
          // unnecessary banners/modals
          resolve({
            salesPackages: [
              {
                selectedForCustomerOrg: true,
                stripeProducts: [],
                package: {
                  createdAt: new Date(),
                  id: "1",
                  name: "name",
                  stripePlanMetadataValue: "hi",
                  updatedAt: new Date(),
                },
              },
            ],
            stripePaymentSourceExists: true,
          });
        });
      }
    },
  });
}
