import { isPlainObject, isUndefined } from "lodash-es";
import { AppProps } from "../App";
import { theGlobal } from "./Global";
import { InfoMessageVariant, showInfoMessage } from "../Styleguide/InfoMessage";

export const baseplateFetch = global.IN_WEBPACK
  ? browserBaseplateFetch
  : serverBaseplateFetch;

async function serverBaseplateFetch<
  ResponseDataType = object,
  ReqBody = object | BodyInit | void
>(
  url: string,
  options: BaseplateFetchOptions<ReqBody> = {},
  rootProps?: AppProps
): Promise<ResponseDataType> {
  if (!rootProps?.baseplateAccount) {
    throw Error(
      "Server-side baseplateFetch requires rootProps to be passed in"
    );
  }
  const [{ app }, httpMocks] = await Promise.all([
    import(/* webpackIgnore: true */ "../../backend/Server"),
    import(/* webpackIgnore: true */ "node-mocks-http"),
  ]);

  return new Promise((resolve, reject) => {
    const req = httpMocks.createRequest({
      // @ts-ignore
      method: options.method || "GET",
      url,
    });
    req.baseplateAccount = rootProps.baseplateAccount!;
    const res = httpMocks.createResponse();
    app._router.handle(req, res, () => {});

    res.on("end", () => {
      const statusCode = res._getStatusCode();
      if (statusCode >= 200 && statusCode < 300) {
        if (statusCode === 204) {
          resolve(null as unknown as ResponseDataType);
        } else {
          resolve(res._getJSONData());
        }
      } else if (statusCode === 401) {
        reject(
          Error(
            `Server simultaneously thinks you're logged in and also not logged in`
          )
        );
      } else {
        const body = res._getJSONData();
        reject(
          Error(
            `Server responded with ${statusCode} ${res._getStatusMessage()} when requesting ${
              options?.method ?? "GET"
            } ${url}. Response body: ${JSON.stringify(body)}`
          )
        );
      }
    });
  });
}

function browserBaseplateFetch<
  ResponseDataType = object,
  ReqBody = BodyInit | void
>(
  url: string,
  options: BaseplateFetchOptions<ReqBody> = {}
): Promise<ResponseDataType> {
  // Default to showing error messages
  const showErrorMessages =
    !options ||
    options.showErrorMessages ||
    isUndefined(options.showErrorMessages);

  if (isPlainObject(options?.body) && !(options?.body instanceof FormData)) {
    // @ts-ignore
    options.body = JSON.stringify(options.body);
    options.headers = options.headers || {};
    options.headers["content-type"] = "application/json";
  }

  return fetch(url, options as RequestInit).then((r) => {
    const jsonBody = r.headers
      .get("content-type")
      ?.includes("application/json");

    if (r.ok) {
      if (r.status === 204) {
        return;
      } else if (jsonBody) {
        return r.json();
      } else if (r.headers.get["content-length"]?.length > 0) {
        return r.text();
      }
    } else if (r.status === 401) {
      if (window.location.pathname !== "/login") {
        window.location.assign("/login");
      }
      return { httpStatus: r.status };
    } else {
      const err = new BaseplateFetchError(
        `Server responded with ${r.status} ${r.statusText} when requesting ${
          options?.method ?? "GET"
        } ${url}`,
        r
      );

      if (showErrorMessages && jsonBody) {
        return r.json().then((body) => {
          if (body?.errors) {
            showInfoMessage({
              title: "Baseplate API Error",
              items: body.errors,
              variant: InfoMessageVariant.error,
            });
          }

          throw err;
        });
      } else {
        throw err;
      }
    }
  });
}

export class BaseplateFetchError extends Error {
  constructor(msg: string, response: Response) {
    super(msg);
    this.response = response;
  }

  response: Response;
}

export type BaseplateFetchOptions<ReqBodyType> = Omit<RequestInit, "body"> & {
  body?: ReqBodyType;
  showErrorMessages?: boolean;
};

declare global {
  interface Window {
    debugFetch: typeof baseplateFetch;
  }

  namespace NodeJS {
    interface Global {
      debugFetch: typeof baseplateFetch;
    }
  }
}

theGlobal.debugFetch = baseplateFetch;
