import { HTMLProps, useContext } from "react";
import { always, toggle } from "kremling";
import { FormContext, FormOrientation, FormTheme } from "./Form";

export function FormFieldLabel(props: FormFieldLabelProps) {
  const { className, children, customMargin, ...otherProps } = props;

  const formContext = useContext(FormContext);
  const themeStyler = themeStylers[formContext.theme];
  const orientation = props.orientation ?? formContext.orientation;

  return (
    <label
      className={always(themeStyler(orientation, props.customMargin || false))
        .always(className as string)
        .always("font-light text-consoleBase")
        .maybe("mb-[0.625rem]", orientation === FormOrientation.vertical)
        .toString()}
      {...otherProps}
    >
      {children}
    </label>
  );
}

const themeStylers: Record<
  FormTheme,
  (orientation: FormOrientation, customMargin: boolean) => string
> = {
  [FormTheme.unstyled]: (orientation: FormOrientation, customMargin) => {
    return always("text-gray-800 text-sm")
      .maybe(toggle("mb-1", "ml-4", orientation).toString(), !customMargin)
      .toString();
  },
  [FormTheme.navy]: (orientation: FormOrientation) => {
    return always("text-darkNavy").toString();
  },
};

export interface FormFieldLabelProps extends HTMLProps<HTMLLabelElement> {
  customMargin?: boolean;
  orientation?: FormOrientation;
}
