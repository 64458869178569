import { Outlet, Routes, Route, useLocation } from "react-router";
import { IconVariant } from "../Styleguide/Icon";
import { ConsolePrimaryNav } from "../Styleguide/ConsolePrimaryNav";
import {
  ConsoleSecondaryNav,
  ConsoleSecondaryNavLink,
} from "../Styleguide/ConsoleSecondaryNav";
import { useRedirect } from "../Utils/useRedirect";
import { ConsoleNavOrgSelect, useCustomerOrg } from "./ConsoleNavOrgSelect";
import { useConsoleParams } from "../Utils/paramHelpers";
import isUUID from "validator/lib/isUUID.js";
import { createContext, useContext, useEffect, useState } from "react";
import { RootPropsContext } from "../App";
import { Modal } from "../Styleguide/Modal";
import { Button, ButtonKind } from "../Styleguide/Button";
import { Anchor } from "../Styleguide/Anchor";
import { useSalesPackagesQuery } from "./OrgSettings/OrgSettingsSubscription";
import dayjs from "dayjs";
import { FreeTrialBanner } from "./FreeTrialBanner";

export const ConsoleContext = createContext({
  hasFreeTrialBanner: false,
});

export function ConsolePage() {
  const rootProps = useContext(RootPropsContext);
  const { selectedCustomerOrg } = useCustomerOrg();
  const location = useLocation();
  useRedirect(
    "/console/microfrontends",
    location.pathname === "/console",
    true
  );
  const { customerOrgId } = useConsoleParams();
  const validCustomerOrgId = customerOrgId && isUUID(customerOrgId);
  useRedirect(
    `/console/${customerOrgId}/microfrontends`,
    location.pathname === `/console/${customerOrgId}` ||
      location.pathname === `/console/${customerOrgId}/`,
    true
  );
  const pathSuffix = location.pathname.replace(`/console/${customerOrgId}`, "");
  const [showBillingModal, setShowBillingModal] = useState(
    selectedCustomerOrg &&
      !selectedCustomerOrg.accountEnabled &&
      location.pathname !==
        `/console/${customerOrgId}/org-settings/subscription`
  );

  useEffect(() => {
    if (
      selectedCustomerOrg &&
      selectedCustomerOrg.accountEnabled !==
        rootProps.userInformation.orgAccountEnabled
    ) {
      rootProps.accountNowEnabled(selectedCustomerOrg.accountEnabled);
    }
  }, [selectedCustomerOrg, selectedCustomerOrg?.accountEnabled, rootProps]);

  const salesPackagesQuery = useSalesPackagesQuery();
  const subscriptionActive = salesPackagesQuery.data
    ? salesPackagesQuery.data.stripePaymentSourceExists &&
      salesPackagesQuery.data.salesPackages.some(
        (p) => p.selectedForCustomerOrg
      )
    : false;
  const customerOrg = useCustomerOrg();
  const daysLeftInFreeTrial =
    !subscriptionActive && customerOrg.selectedCustomerOrg
      ? 30 - dayjs().diff(customerOrg.selectedCustomerOrg!.createdAt, "days")
      : 0;

  return (
    <ConsoleContext.Provider
      value={{ hasFreeTrialBanner: !subscriptionActive }}
    >
      <ConsolePrimaryNav />
      {!subscriptionActive && (
        <FreeTrialBanner daysLeft={daysLeftInFreeTrial} />
      )}
      <ConsoleSecondaryNav hasFreeTrialBanner={!subscriptionActive}>
        <Routes>
          <Route path="profile" element={null} />
          <Route path="*" element={<ConsoleNavOrgSelect />} />
        </Routes>
        {customerOrgId && (
          <>
            <ConsoleSecondaryNavLink
              to={`/console/${customerOrgId}/microfrontends`}
              icon={IconVariant.puzzle}
            >
              Microfrontends
            </ConsoleSecondaryNavLink>
            <ConsoleSecondaryNavLink
              to={`/console/${customerOrgId}/environments`}
              icon={IconVariant.globe}
            >
              Environments
            </ConsoleSecondaryNavLink>
            <ConsoleSecondaryNavLink
              to={`/console/${customerOrgId}/web-apps`}
              icon={IconVariant.webApp}
            >
              Web Apps
            </ConsoleSecondaryNavLink>
            <ConsoleSecondaryNavLink
              to={`/console/${customerOrgId}/org-settings`}
              icon={IconVariant.settings}
            >
              Settings
            </ConsoleSecondaryNavLink>
          </>
        )}
      </ConsoleSecondaryNav>
      {(validCustomerOrgId || !pathSuffix) && <Outlet />}
      {showBillingModal && (
        <Modal
          close={() => setShowBillingModal(false)}
          title="Billing Information Required"
        >
          <>
            <p>Your Baseplate 30-day free-trial has ended.</p>
            <p className="mt-4">
              You may preview Baseplate Console, but cannot make modifications
              to any data until your subscription is active.
            </p>
            <div className="mt-8 flex justify-start">
              <Button
                kind={ButtonKind.secondary}
                type="button"
                onClick={() => setShowBillingModal(false)}
              >
                Ignore
              </Button>
              <Anchor
                kind={ButtonKind.primary}
                to={`/console/${customerOrgId}/org-settings/subscription`}
                className="ml-4"
                onClick={() => setShowBillingModal(false)}
              >
                Add Billing Information
              </Anchor>
            </div>
          </>
        </Modal>
      )}
    </ConsoleContext.Provider>
  );
}
