import { useContext } from "react";
import { RootPropsContext } from "../App";

export function StaticImage(props: StaticImageProps) {
  const rootProps = useContext(RootPropsContext);

  return (
    <img
      alt={props.alt}
      src={rootProps.assetBase + props.path}
      style={{ height: props.height }}
    />
  );
}

export function useAssetUrls(...paths: string[]) {
  const rootProps = useContext(RootPropsContext);
  return paths.map((path) => rootProps.assetBase + path);
}

export interface StaticImageProps {
  path: string;
  alt: string;
  height?: string;
}
