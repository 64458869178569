import { useSuspenseQuery } from "@tanstack/react-query";
import { CustomerOrg } from "../../backend/DB/Models/CustomerOrg/CustomerOrg";
import { EndpointGetAllCustomerOrgsResBody } from "../../backend/RestAPI/OrgSettings/GetAllCustomerOrgs";
import { baseplateFetch } from "../Utils/baseplateFetch";
import { ConsoleSecondaryNavItem } from "../Styleguide/ConsoleSecondaryNav";
import { useRedirect } from "../Utils/useRedirect";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { RootPropsContext } from "../App";
import { isEmpty } from "lodash-es";
import { Listbox, ListboxOption } from "@reach/listbox";
import { VisuallyHidden } from "@reach/visually-hidden";
import "@reach/listbox/styles.css";
import "./ConsoleNavOrgSelect.css";
import { Icon, IconVariant } from "../Styleguide/Icon";

export function ConsoleNavOrgSelect() {
  const { allUserCustomerOrgs, selectedCustomerOrg } = useCustomerOrg();
  const location = useLocation();
  const pathParts = location.pathname
    .split("/")
    .filter((part) => !isEmpty(part.trim()));
  pathParts.splice(0, 2);
  const pathSuffix = pathParts.join("/");
  useRedirect(
    `/console/select-org?pathSuffix=${pathSuffix}`,
    !selectedCustomerOrg
  );

  const initial = selectedCustomerOrg?.name.charAt(0) ?? "\u2014";

  return (
    <ConsoleSecondaryNavItem
      className="org-selector-parent"
      customHeight
      customHover
      left={
        <div className="lg:hidden flex justify-center py-[28px] text-base text-darkNavy">
          {initial}
        </div>
      }
      right={
        <>
          {allUserCustomerOrgs.length > 1 ? (
            <OrgSelector pathSuffix={pathSuffix} />
          ) : (
            <div className="hidden lg:block text-base uppercase tracking-widest py-[28px] pl-[23px] font-semibold border-b border-darkNavy w-full">
              {selectedCustomerOrg?.name}
            </div>
          )}
        </>
      }
    />
  );
}

export function useCustomerOrg(): ConsoleCustomerOrg {
  const rootProps = useContext(RootPropsContext);
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const customerOrgId = pathParts[2] ?? null;
  const query = useSuspenseQuery({
    queryKey: [`user-customer-orgs`],

    queryFn: async () => {
      const response = await baseplateFetch<EndpointGetAllCustomerOrgsResBody>(
        "/api/orgs/all",
        {},
        rootProps
      );
      return response.customerOrgs;
    },
  });

  return {
    allUserCustomerOrgs: query.data!,
    selectedCustomerOrg: query.data!.find(
      (customerOrg) => customerOrg.id === customerOrgId
    ),
  };
}

export interface ConsoleCustomerOrg {
  selectedCustomerOrg?: CustomerOrg;
  allUserCustomerOrgs: CustomerOrg[];
}

function OrgSelector(props: OrgSelectorProps) {
  const { allUserCustomerOrgs, selectedCustomerOrg } = useCustomerOrg();
  const navigate = useNavigate();

  const selectedCustomerOrgId =
    selectedCustomerOrg?.id ?? allUserCustomerOrgs[0].id;

  return (
    <>
      <VisuallyHidden id="org-selector">Select an organization</VisuallyHidden>
      <Listbox
        className="org-selector my-7 ml-2"
        aria-labelledby="org-selector"
        value={selectedCustomerOrgId}
        onChange={(newCustomerOrgId) => {
          navigate(`/console/${newCustomerOrgId}/${props.pathSuffix}`);
        }}
        portal={false}
        arrow={<Icon variant={IconVariant.expanded} />}
      >
        {allUserCustomerOrgs.map((customerOrg) => (
          <ListboxOption
            label={customerOrg.name}
            value={customerOrg.id}
            key={customerOrg.id}
            className="text-darkNavy font-semibold leading-[.8px]"
          >
            <div className="flex">
              <div className="w-[56px] border-r border-white py-5 flex items-center">
                {selectedCustomerOrgId === customerOrg.id && (
                  <Icon variant={IconVariant.check} className="ml-[1.375rem]" />
                )}
              </div>
              <div className="py-5 pl-2">{customerOrg.name}</div>
            </div>
          </ListboxOption>
        ))}
      </Listbox>
    </>
  );
}

interface OrgSelectorProps {
  pathSuffix: string;
}
