import { always, maybe } from "kremling";
import { FormEvent, ReactNode, useContext, useState } from "react";
import { Anchor } from "./Anchor";
import { Button, ButtonKind } from "./Button";
import { Icon, IconKind, IconVariant } from "./Icon";
import { useLocation } from "react-router";
import { ConsoleContext } from "../Console/ConsolePage";
import { Modal, ModalActions } from "./Modal";
import { Form, FormTheme } from "./Form";
import { FormField } from "./FormField";
import { FormFieldLabel } from "./FormFieldLabel";
import { TextArea } from "./TextArea";
import { useMutation } from "@tanstack/react-query";
import { baseplateFetch } from "../Utils/baseplateFetch";

export function ConsoleSecondaryNav(props: ConsoleSecondaryNavProps) {
  const [productFeedbackOpen, setProductFeedbackOpen] =
    useState<boolean>(false);
  const consoleContext = useContext(ConsoleContext);
  const [feedback, setFeedback] = useState("");

  const sendFeedbackMutation = useMutation<void, Error, FormEvent>({
    mutationFn: async (evt) => {
      evt.preventDefault();

      await baseplateFetch(`/api/product-feedback`, {
        method: "POST",
        body: {
          feedback,
        },
      });

      setFeedback("");
      setProductFeedbackOpen(false);
    },
  });

  return (
    <>
      <nav
        className={always(
          `fixed left-0 w-[57px] max-w-[57px] lg:w-[360px] lg:max-w-[360px] lg:border-r h-screen top-0 right-4 lg:right-auto bg-white text-sm text-darkNavy overflow-x-hidden`
        ).toggle("top-[108px]", "top-[64px]", props.hasFreeTrialBanner)}
      >
        <ul
          className="flex flex-col justify-between bg-white"
          style={{
            height: consoleContext.hasFreeTrialBanner
              ? "calc(100vh - 108px)"
              : "calc(100vh - 64px)",
          }}
        >
          <div className="flex flex-col">{props.children}</div>
          <ConsoleSecondaryNavItem
            left=""
            right=""
            className="grow"
            customHeight
            customHover
          />
          <ConsoleSecondaryNavItem
            left={
              <Button
                kind={ButtonKind.transparent}
                className={always(secondaryNavClickableClasses()).always(
                  "flex justify-center"
                )}
                onClick={() => {
                  setProductFeedbackOpen(true);
                }}
              >
                <Icon
                  variant={IconVariant.feedback}
                  fill={productFeedbackOpen ? "currentColor" : "#b3b3b3"}
                  kind={IconKind.solid}
                  size={20}
                />
              </Button>
            }
            right={
              <Button
                kind={ButtonKind.transparent}
                className={always(secondaryNavClickableClasses())
                  .always("pl-[1.25rem] text-consoleBase")
                  .toggle(
                    "text-darkNavy",
                    "text-gray-600",
                    productFeedbackOpen
                  )}
                onClick={() => {
                  setProductFeedbackOpen(true);
                }}
              >
                Provide feedback
              </Button>
            }
          />
        </ul>
      </nav>
      {productFeedbackOpen && (
        <Modal
          close={() => setProductFeedbackOpen(false)}
          title="Product feedback"
        >
          <Form theme={FormTheme.navy} onSubmit={sendFeedbackMutation.mutate}>
            <p className="font-light mb-8">
              Have an idea for a new feature? An issue that you’ve encountered?
              We would love to hear about your experience using Baseplate.
            </p>
            <FormField>
              <FormFieldLabel htmlFor="feedback-text">
                Describe your feedback
              </FormFieldLabel>
              <TextArea
                id="feedback-text"
                value={feedback}
                onChange={(evt) => setFeedback(evt.target.value)}
                rows={9}
                autoFocus
              />
            </FormField>
            <ModalActions
              cancel={() => setProductFeedbackOpen(false)}
              submitText="Submit"
            />
          </Form>
        </Modal>
      )}
    </>
  );
}

export function ConsoleSecondaryNavItem(props: ConsoleSecondaryNavItemProps) {
  return (
    <li
      className={always(
        `text-left secondary-nav-row flex items-center w-full font-light`
      )
        .maybe("h-[3.375rem] min-h-[3.375rem]", !props.customHeight)
        .always(props.className ?? "")}
    >
      <div
        className={always(
          "border-r h-full border-consoleGray-200 w-[57px] min-w-[57px]"
        ).maybe("pb-[0.625rem]", !props.customHeight)}
      >
        {props.left}
      </div>
      <div
        className={always("w-full self-start")
          .maybe("h-[2.75rem]", !props.customHeight)
          .maybe("hover:bg-gray-200", !props.customHover)}
      >
        {props.right}
      </div>
    </li>
  );
}

export interface ConsoleSecondaryNavItemProps {
  left: ReactNode;
  right: ReactNode;
  className?: string;
  customHeight?: boolean;
  customHover?: boolean;
}

export function ConsoleSecondaryNavLink(props: ConsoleSecondaryNavLinkProps) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(props.to);

  return (
    <ConsoleSecondaryNavItem
      left={
        <div className="border-r border-gray-200 w-[57px] min-w-[57px] h-full">
          {props.icon && (
            <Anchor
              kind={ButtonKind.transparent}
              to={props.to}
              className={always(secondaryNavClickableClasses())
                .always("flex justify-center")
                .toggle("text-royalBlue", "text-gray-600", isActive)}
              onClick={() => {
                props.onClick?.apply(null, arguments);
              }}
            >
              <Icon
                variant={props.icon}
                fill={"currentColor"}
                kind={IconKind.solid}
                size={20}
              />
            </Anchor>
          )}
        </div>
      }
      right={
        <>
          <div
            className={maybe(props.className ?? "", props.className)
              .toggle(
                "bg-gray-200 border-l-2 border-peach",
                "border-l-2 border-transparent",
                isActive
              )
              .always("h-full text-consoleBase")}
          >
            <Anchor
              kind={ButtonKind.transparent}
              to={props.to}
              className={secondaryNavClickableClasses()}
              onClick={() => {
                props.onClick?.apply(null, arguments);
              }}
            >
              <div className="collapsible ml-[1.25rem]">{props.children}</div>
            </Anchor>
          </div>
        </>
      }
    />
  );
}

function secondaryNavClickableClasses(): string {
  return `w-full text-left inline-flex items-center justify-start h-full`;
}

interface ConsoleSecondaryNavProps {
  hasFreeTrialBanner: boolean;
  children: ReactNode;
}

interface ConsoleSecondaryNavLinkProps {
  children: ReactNode;
  to: string;
  icon?: IconVariant;
  onClick?: () => void;
  className?: string;
}
