import { createRoot } from "react-dom/client";
import { Button, ButtonKind } from "./Button";
import { Icon, IconVariant } from "./Icon";
import { always } from "kremling";

export function showInfoMessage(props: Omit<InfoMessageProps, "close">) {
  const containerEl = document.createElement("div");
  document.body.appendChild(containerEl);
  const root = createRoot(containerEl);
  root.render(<InfoMessage close={unmount} {...props} />);

  const timeoutId = setTimeout(unmount, 4000);

  function unmount() {
    clearTimeout(timeoutId);
    root.unmount();
  }
}

export function InfoMessage(props: InfoMessageProps) {
  return (
    <div
      className={always(
        "fixed bottom-1 mx-auto text-white max-w-md left-1/2 -translate-x-1/2 p-5 z-40"
      ).toggle(
        "bg-darkNavy",
        "bg-red-800",
        props.variant === InfoMessageVariant.info
      )}
    >
      <Button
        kind={ButtonKind.transparent}
        onClick={props.close}
        className="absolute top-2 right-2"
      >
        <Icon variant={IconVariant.close} />
      </Button>
      <div className="text-lg bold mb-2">{props.title}</div>
      {(props.items ?? []).map((item) => (
        <div key={item} className="text-sm">
          {typeof item === "object" ? JSON.stringify(item) : item}
        </div>
      ))}
    </div>
  );
}

interface InfoMessageProps {
  variant: InfoMessageVariant;
  title: string;
  items?: string[];
  close(): any;
}

export enum InfoMessageVariant {
  error = "error",
  info = "info",
}
