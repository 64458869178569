import { VisuallyHidden } from "@reach/visually-hidden";
import { always } from "kremling";
import { SVGProps, useEffect, useState } from "react";

export function Loader(props: LoaderProps) {
  const { description, delay = 100, white = false, ...otherProps } = props;

  const [show, setShow] = useState(delay === 0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [show, delay]);

  if (!show) {
    return null;
  }

  return (
    <>
      <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24" {...otherProps}>
        <circle
          cx="12"
          cy="12"
          r={"1.5rem"}
          className={always("fill-current").toggle(
            "text-white",
            "text-primary",
            white
          )}
        ></circle>
      </svg>
      <VisuallyHidden>{description}</VisuallyHidden>
    </>
  );
}

export interface LoaderProps extends SVGProps<SVGSVGElement> {
  description: string;
  delay?: number;
  white?: boolean;
}
