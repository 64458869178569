import { always } from "kremling";
import { createContext, HTMLProps } from "react";

export function Form(props: FormProps) {
  const {
    theme = FormTheme.unstyled,
    orientation = FormOrientation.vertical,
    children,
    className = "",
    ...otherProps
  } = props;

  return (
    <FormContext.Provider value={{ theme, orientation }}>
      <form
        className={always(className).maybe(
          "text-darkNavy",
          theme === FormTheme.navy
        )}
        {...otherProps}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
}

export enum FormTheme {
  unstyled = "unstyled",
  navy = "navy",
}

export enum FormOrientation {
  vertical = "vertical",
  horizontal = "horizontal",
}

export interface FormProps extends HTMLProps<HTMLFormElement> {
  theme?: FormTheme;
  orientation?: FormOrientation;
}

export interface FormContextData {
  theme: FormTheme;
  orientation: FormOrientation;
}

export const FormContext = createContext<FormContextData>({
  theme: FormTheme.unstyled,
  orientation: FormOrientation.vertical,
});
